import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';

// Connects to data-controller="dialog"
export default class extends Controller {
  static targets = ['drawer'];

  connect() {
    document.body.addEventListener('click', (e) => {
      if (e.target.dataset.toggleButton !== 'true' && !this.drawerTarget.contains(e.target)) {
        this.close();
      }
    });
  }

  close(e) {
    leave(this.drawerTarget);
    leave(this.element);
  }
}
